<style scoped>

td {
    padding-right: 1em;
}

</style>

<template>
    
    <div class="pa-3" style="min-width: 10em;">
            <h2>Details</h2>
            
            <v-progress-linear
            indeterminate
      v-if='loading'
    ></v-progress-linear>

            <table v-else-if="transaction">
                <tr>
                    <td><b>{{$t('labels.date')}}</b></td>
                    <td>{{$formatDate(transaction.transactionDate)}}</td>
                </tr>
                <tr v-if="transaction.brandName">
                    <td><b>{{$t('labels.brand')}}</b></td>
                    <td>{{transaction.brandName}}</td>
                </tr>
                <tr v-if="transaction.cashierName">
                    <td><b>{{$t('labels.receiver')}}</b></td>
                    <td>{{transaction.cashierFirstName}}</td>
                </tr>
                <tr>
                    <td><b>{{$t('labels.amount')}}</b></td>
                    <td>{{$asCurrency(transaction.amount)}}</td>
                </tr>
                <tr v-if="transaction.loyaltyDiscount">
                    <td><b>{{$t('labels.loyaltyDiscount')}}</b></td>
                    <td>{{transaction.loyaltyDiscount}}</td>
                </tr>
                <tr v-if="transaction.reference">
                    <td><b>{{$t('labels.reference')}}</b></td>
                    <td>{{transaction.reference}}</td>
                </tr>
                <tr v-if="transaction.direction == 'IN'">
                    <td><b>{{$t('labels.shopper')}}</b></td>
                    <td>{{transaction.sender.name}}</td>
                </tr>
                <tr>
                    <td><b>{{$t('labels.status')}}</b></td>
                    <td><v-chip :color="status.color">{{status.text}}</v-chip></td>
                </tr>
            </table>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            transaction: null,
            loading: false
        }
    },

    computed:{
        status() {
            if (!this.transaction) return {}

            const chip = {
                text: this.$t('labels.transactionStatus.' + this.transaction.status),
                color: "info"
            }

            if (this.transaction.status == 'PAID') 
                chip.color = "green"

            return chip
        }
    },

    async mounted() {
        this.loading  = true
        this.transaction = await this.$api.getTransaction(this.id)
        this.loading = false
    }
}
</script>