
<template>

     <base-material-card
        icon="mdi-format-list-bulleted"
        :title="title"
        class="px-5 py-3"
      >

       <v-layout row>

      <v-flex class="pa-3">
        <list ref="listcomp" />
      </v-flex>

      <v-flex class="pa-3">
        <trx-filter @apply="applyFilter" />
      </v-flex>

        

      </v-layout>

    </base-material-card>
    
</template>


<script>
import trxFilter from './trxFilter';
import list from './list';
export default {   

    data() {
      return {
        usedCutoffHour: "00:00:00"
      }
    },
    
    computed: {
      title() { return this.$t('labels.overview') + ' - ' + this.$t('labels.endOfDay') + ' ' + this.usedCutoffHour }
    },

    components: {
      'trx-filter': trxFilter,
      list
    },

    methods: {
      applyFilter(filter) {
        this.usedCutoffHour = filter.cutoffHour
        this.$refs.listcomp.load(filter)
      }
    }

}
</script>