<style lang="scss">
@import '@/sass/colors.scss';
.load-more {
  color: $bonsai-blue;
}
.load-more:hover {
  cursor: pointer;
}
tr.actionable:hover {
  cursor: pointer;
}
.v-row-group__header td {
  font-weight: bold !important;
}
</style>

<template>
<div>

  <v-data-table
    :headers="headers"
    :items="transactions"
    class="elevation-0"
    disable-pagination
    :loading="loading"
    :hide-default-footer="true"
    group-by="group"
    group-desc
  >
   <template v-slot:group.header="props" style="background-color: red">
      <td>{{ $formatDate(props.group, true) }}</td>
      <td>{{ $asCurrency(props.items.reduce((acc, cur) => acc + cur.amount + cur.loyaltyDiscount, 0))}}</td>
      <td>{{ $asCurrency(props.items.reduce((acc, cur) => acc + cur.loyaltyDiscount, 0))}}</td>
      <td>{{ $asCurrency(props.items.reduce((acc, cur) => acc + cur.amount, 0))}}</td>
       
    </template>

    <template v-slot:item="props">
    <tr class="actionable"  @click="openTrx(props.item.id)">
      <td>{{ $formatDate(props.item.transactionDate) }}</td>
      <td>{{ $asCurrency(props.item.amount + props.item.loyaltyDiscount) }}</td>
      <td>{{ $asCurrency(props.item.loyaltyDiscount) }}</td>
      <td>{{ $asCurrency(props.item.amount) }}</td>
      </tr>
    </template>

    <template v-slot:body.append="props">
    <tr v-if="nextPage">
      <td v-if="!loading" @click="loadMore" colspan="5" class="load-more">
      {{$t('transactions.loadMore')}}</td>
      <td v-if="loading" colspan="5" class="load-more">
      {{$t('labels.loading')}}</td>
      </tr>
    </template>
  </v-data-table>

  <v-btn class="mt-3" color="primary" :loading="loading" :disabled="loading" @click="downloadCsv">Download csv</v-btn>
  </div>
  
</template>


<script>
import Details from './details'
import Question from '@/components/Question'
export default {   
    data() {
        return {
            transactions: [],
            nextPage: null,
            loading: false,
            filters: [],
            dateFrom: null,
            dateTo: null,
            cutoffHour: null
        }
    },
    computed: {
      headers() {
        return [
                { text: this.$t('labels.date'), value: 'transactionDate', sortable: false },
                { text: this.$t('labels.amount'), value: 'requestedAmount', sortable: false  },
                { text: this.$t('labels.loyaltyDiscount'), value: 'loyaltyDiscount', sortable: false },
                { text: this.$t('labels.finalAmount'), value: 'amount', sortable: false }
            ]
      }
    },
    methods: {
      async load(_) {
        this.filters = _.filters || []
        this.dateFrom = _.dateFrom
        this.dateTo = _.dateTo
        this.cutoffHour = _.cutoffHour
          
        this.transactions = []
        this._load(0)
      },
      async loadMore() {
        this._load(this.nextPage)
      },
      async _load(page) {
        this.loading = true
        
        let data = await this.$api.getTransactions(page, this.filters, this.dateFrom, this.dateTo, this.cutoffHour)
        if (data.transactions.length == 0 && data.nextPage)
          data = await this.$api.getTransactions(data.nextPage, this.filters, this.dateFrom, this.dateTo, this.cutoffHour)
          
        this.transactions.push(...data.transactions)
        this.nextPage = data.nextPage
        this.loading = false
      },
      openTrx(id) {
        this.$openModal(Details, { id }, this)
      },
      downloadCsv() {

        const data = {
          title: this.$t('actions.exportCsv.title'), 
          question: this.$t('actions.exportCsv.message')
        }
        this.$openModal(Question, data, this).then(() => {
          this.loading = true
          this.$api.downloadCsv(this.filters, this.dateFrom, this.dateTo, this.cutoffHour)
            .finally(() => { this.loading = false })
        }) 
        
      }
    }

}
</script>