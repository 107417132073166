<style scoped>
.v-menu__content {
    background-color:white
}
</style>

<template>
    <div>

      <div class="text-h3">{{$t('labels.filters')}}</div>

      <date-picker v-model="dateFrom" :label="$t('labels.dateFrom')" />

      <date-picker v-model="dateTo" :label="$t('labels.dateTo')" />

        <div v-for="(filter, index) in filters" :key="'filter' + index">
            <v-subheader>{{$t(filter.translationKey)}}</v-subheader>
            <v-checkbox v-for="option in filter.options" 
                :input-value="getFilterValue(filter.id)"
                @change="setFilterValue(filter.id, $event)"
                :value="option.value"
                :label="option.label ? option.label : $t(option.translationKey)"
                :key="option.value" />
        </div>
        
        <v-btn color="primary" @click="applyFilter">{{$t('labels.apply')}}</v-btn>

    </div>

</template>

<script>
import datepicker from '@/components/datepicker';
import _ from 'lodash'
import Message from '@/components/Message'
export default {

    components: {
      'date-picker': datepicker
    },

    data() {
        return {
            filters: [],
            filterValues: [], // [{id, values}]
            dateFrom: null, // A moment date
            dateTo: null, // A moment date
            allReceivingAccounts: []
        }
    },

    async mounted() {
        const promises = await Promise.all([this.$api.loadTrxFilter(), this.$api.getAccounts()])
        this.filters = promises[0]
        this.allReceivingAccounts = promises[1]
        this.applyFilter()
    },

    methods: {
        getFilterValue(id) {
            const existing = this.filterValues.find(p => p.id == id)
            if (existing) return existing.values
            return []
        },
        setFilterValue(id, values) {
            const existing = this.filterValues.find(p => p.id == id)
            if (existing)
                existing.values = values
            else
                this.filterValues.push({id, values})

        },
        async applyFilter() {
          const cutoffHour = await this.calculateCutoffHour()
          this.$emit("apply", {filters: this.filterValues, dateFrom: this.dateFrom, dateTo: this.dateTo, cutoffHour})
        },
        async calculateCutoffHour() {
          let cutoffHourOptions = this.allReceivingAccounts
              .map(p => p.brand.location.cutoffHour)

          const accountIdsFilter = this.filters.find(p => p.id == 'accountIds')
          if (accountIdsFilter) { // the filter does not always exist !
            const chosenFilter = this.filterValues.find(p => p.id == 'accountIds')
            const hasSubsetSelected = chosenFilter && chosenFilter.values && chosenFilter.values.length
            const selectedAccountIds = hasSubsetSelected ? chosenFilter.values : accountIdsFilter.options.map(p => p.value)
            cutoffHourOptions = this.allReceivingAccounts
              .filter(p => selectedAccountIds.includes(p.id))
              .map(p => p.brand.location.cutoffHour)
          }

          cutoffHourOptions = _.uniq(cutoffHourOptions)
         
          if (cutoffHourOptions.length == 0) 
            return "00:00:00"
          else if (cutoffHourOptions.length == 1) 
            return cutoffHourOptions[0]
          else {
            await this.$openModal(Message, { message: this.$t('labels.multipleCutoffsFound')  }, this)
            return "00:00:00"
          }
        }
    }
}
</script>