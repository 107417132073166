<template>
    <div>
        <p v-html="message"></p>

        <v-btn @click="ok" color="primary">{{$t('labels.ok')}}</v-btn>
    </div>
</template>

<script>
export default {
    props: {
        message: String
    },
    methods: {
        ok() {
            this.$emit('ok')
        }
    }
}
</script>
