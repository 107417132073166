<template>
    
<v-menu
          v-model="datepicker"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              clearable
              v-model="dateFormatted"
              :label="label"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              @click:clear="dateString = null"
            ></v-text-field>
          </template>
          <v-date-picker
            :locale="$i18n.locale"
            v-model="dateString"
            no-title
            @input="datepicker = false"
          ></v-date-picker>
        </v-menu>

</template>

<script>
import moment from 'moment'
export default {
    props: {
        value: String,
        label: String
    },
    watch: {
        value: {
            immediate: true,
            handler(val) {
                this.updateInput(val)
            }
        },
      dateString (val) {
        this.updateInput(val)
      }
    },
    data() {
        return {
            datepicker: null, // picker visible?
            dateFormatted: null,  // Visible in textfield
            dateString: "", // string YYYY-MM-DD coming from picker
            date: null, // A moment date
        }
    },
    methods: {
        updateInput(val) {
            if (!val) this.date = null
            else this.date = moment(val)//.endOf('d')
            this.dateFormatted = this.formatDate(this.date)
            
            const emitValue = (this.date ? moment(this.date).toISOString() : null)
            console.log(emitValue)
            this.$emit('input', emitValue)
        },
        formatDate (date) {
            if (!date) return null
            return date.format('ll')
        },
    }
}
</script>
